import Bowser from 'bowser';

export const OS = {
  iOS: 'iOS',
  // https://github.com/lancedikson/bowser/issues/329
  macOS: 'macOS',
  android: 'Android',
};

export function getParser() {
  return Bowser.getParser(window.navigator.userAgent);
}

export function isAppleDevice() {
  const parse = getParser();
  const os = parse.getOS();
  const { name } = os;
  const { iOS, macOS } = OS;
  const isTs = 'ontouchstart' in window;

  return name === iOS || (name === macOS && isTs);
}

export function isAndroidDevice() {
  const parse = getParser();
  const os = parse.getOS();
  const { name } = os;
  const { android } = OS;

  return name === android;
}

export function isDevice() {
  const parse = getParser();
  const platform = parse.getPlatform();
  return platform.type === 'mobile' || platform.type === 'tablet';
}

export function isMobile() {
  const parse = getParser();
  const platform = parse.getPlatform();
  return platform.type === 'mobile';
}
